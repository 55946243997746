import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/Hero"
import ThoughtCard from "../components/ThoughtCard"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import SEO from "../components/seo"

const ThoughtsPage = ({ data, location }) => {
  const thoughts = data.thoughts.edges[0].node
  const posts = data.posts.edges

  return (
    <Layout
      hero={
        <Hero
          image={thoughts.hero_background}
          heading={thoughts.hero_heading}
          subhead={thoughts.hero_subhead}
        />
      }
    >
      <SEO title="Thoughts" path={location.pathname} />
      <Container as="section">
        <Row>
          {posts.map(post => (
            <ThoughtCard data={post.node} />
          ))}
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    thoughts: allButterPage(filter: { slug: { eq: "thoughts" } }) {
      edges {
        node {
          hero_heading
          hero_subhead
          hero_background
        }
      }
    }
    posts: allButterPost(
      sort: { fields: published, order: DESC }
      filter: { categories: { elemMatch: { slug: { nin: "webinar" } } } }
    ) {
      edges {
        node {
          slug
          featured_image
          title
          summary
          published
          author {
            first_name
            last_name
          }
        }
      }
    }
  }
`

export default ThoughtsPage
