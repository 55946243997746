import React from "react"
import { dateFormat, trailingslashit } from "./helpers"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

const ThoughtCard = ({ data, as }) => {
  const CardElement = as ? as : "article"
  const Heading = CardElement === "section" ? "h1" : "h3"

  return (
    <CardElement
      className="mobile thought__card"
      style={{ backgroundImage: `url(${data.featured_image})` }}
    >
      <Container>
        <Row className="py-3">
          <Col md={8} lg={6}>
            <div className="thought__card__info">
              <Heading className="thought__card__title">{data.title}</Heading>
              <Row className="my-4 muted">
                <Col sm={12} md={6}>
                  {data.author.first_name} {data.author.last_name}
                </Col>
                <Col sm={12} md={6}>
                  {dateFormat(data.published)}
                </Col>
              </Row>
              <p className="thought__card__excerpt">{data.summary}</p>
              <Button
                variant="outline-light"
                href={trailingslashit(`/thoughts/${data.slug}`)}
              >
                Read Post
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </CardElement>
  )
}

export default ThoughtCard
